<template>
    <div>
        <div class="content">
            <a-button type="primary" style="margin-right: 20px;" @click="submitOrder">
                提交新工单
            </a-button>
            <a-button type="primary" @click="gotoHome">
                返回主页
            </a-button>
        </div>
        <p>
            <span>已受理</span>
            <span class="orderNum">1</span>
            <span>完成</span>
            <span class="orderNum">0</span>
        </p>
        <p class="orderTitle">
            工单列表
        </p>
        <div class="orderChoose">
            <div style="display: flex">
                <a-select default-value="all" style="width: 220px" @change="handleType">
                    <a-select-option value="all">
                        全部
                    </a-select-option>
                    <a-select-option value="ready">
                        已受理
                    </a-select-option>
                    <a-select-option value="finish">
                        完成
                    </a-select-option>
                </a-select>
                <a-select default-value="all" style="width: 220px; margin-left: 30px;" @change="handleTime">
                    <a-select-option value="all">
                        全部
                    </a-select-option>
                    <a-select-option value="today">
                        今天
                    </a-select-option>
                    <a-select-option value="just">
                        最近7天
                    </a-select-option>
                </a-select>
            </div>
            <a-input-search placeholder="请输入该订单编号或标题" style="width: 400px;" enter-button @search="onSearch" />
        </div>
        <a-table style="margin-bottom: 20px" :locale="{emptyText: '暂无数据'}" :columns="columns" :pagination="false" :data-source="data" />
    </div>
</template>

<script>
const columns = [
    {
        title: '工单编号',
        dataIndex: 'key',
        key: 'key'
    },
    {
        title: '工单类型',
        dataIndex: 'type',
        key: 'type'
    },
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: '状态',
        dataIndex: 'status',
        key: 'status'
    },
    {
        title: '提交时间',
        dataIndex: 'time',
        key: 'time'
    },
    {
        title: '操作',
        dataIndex: 'handle',
        key: 'handle'
    },
]

const data = [
    {
        key: '1',
        type: '类型1',
        title: '行车记录仪',
        status: '已受理',
        time: '今天',
        handle: '',
    },
    {
        key: '2',
        type: '类型2',
        title: '行车记录仪',
        status: '已受理',
        time: '今天',
        handle: '',
    },
    {
        key: '3',
        type: '类型3',
        title: '传感器',
        status: '完成',
        time: '最近7天',
        handle: '',
    },
]
export default {
    name: "Index",
    data() {
        return {
            columns,
            data
        }
    },
    methods: {
        handleType(val) {
            console.log(val)
        },
        handleTime(val) {
            console.log(val)
        },
        onSearch(val) {
            console.log(val)
        },
        gotoHome() {
            this.$router.push('/home')
        },
        submitOrder() {
            this.$router.push('/order-submit')
        }
    }
}
</script>

<style lang="scss" scoped>
    .content {
        display: flex;
        justify-content: flex-end;
        margin: -60px 0 30px 0;
        width: 100%;
    }
    .orderNum {
        color: red;
        margin: 0 60px;
    }
    .orderTitle {
        margin: 30px 0;
    }
    .orderChoose {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
    }
</style>
