var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "a-button",
            {
              staticStyle: { "margin-right": "20px" },
              attrs: { type: "primary" },
              on: { click: _vm.submitOrder },
            },
            [_vm._v(" 提交新工单 ")]
          ),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.gotoHome } },
            [_vm._v(" 返回主页 ")]
          ),
        ],
        1
      ),
      _vm._m(0),
      _c("p", { staticClass: "orderTitle" }, [_vm._v(" 工单列表 ")]),
      _c(
        "div",
        { staticClass: "orderChoose" },
        [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "220px" },
                  attrs: { "default-value": "all" },
                  on: { change: _vm.handleType },
                },
                [
                  _c("a-select-option", { attrs: { value: "all" } }, [
                    _vm._v(" 全部 "),
                  ]),
                  _c("a-select-option", { attrs: { value: "ready" } }, [
                    _vm._v(" 已受理 "),
                  ]),
                  _c("a-select-option", { attrs: { value: "finish" } }, [
                    _vm._v(" 完成 "),
                  ]),
                ],
                1
              ),
              _c(
                "a-select",
                {
                  staticStyle: { width: "220px", "margin-left": "30px" },
                  attrs: { "default-value": "all" },
                  on: { change: _vm.handleTime },
                },
                [
                  _c("a-select-option", { attrs: { value: "all" } }, [
                    _vm._v(" 全部 "),
                  ]),
                  _c("a-select-option", { attrs: { value: "today" } }, [
                    _vm._v(" 今天 "),
                  ]),
                  _c("a-select-option", { attrs: { value: "just" } }, [
                    _vm._v(" 最近7天 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-input-search", {
            staticStyle: { width: "400px" },
            attrs: {
              placeholder: "请输入该订单编号或标题",
              "enter-button": "",
            },
            on: { search: _vm.onSearch },
          }),
        ],
        1
      ),
      _c("a-table", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: {
          locale: { emptyText: "暂无数据" },
          columns: _vm.columns,
          pagination: false,
          "data-source": _vm.data,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("已受理")]),
      _c("span", { staticClass: "orderNum" }, [_vm._v("1")]),
      _c("span", [_vm._v("完成")]),
      _c("span", { staticClass: "orderNum" }, [_vm._v("0")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }